import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'provider-edit',
  templateUrl: './provider-edit.component.html',
  styleUrls: ['./provider-edit.component.less']
})

export class ProviderEditComponent  implements OnChanges{
  title: string;
  provider: SmsProvider;
  form: FormGroup;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();
   
  }

  createForm() {
      this.form = this.fb.group({
        Name: ['smsc.ru', Validators.required],
        ApiString: ['http://smsc.ru/sys/send.php?login=dakyamaha&psw=Nyb1Ci6Cor3&charset=utf-8&phones=#phones&mes=#message', Validators.required]
      });
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/SMSProvider/" + this.selectedId;
      this.http.get<SmsProvider>(url).subscribe(result => {
        this.provider = result;
        this.form.setValue({
          Name: this.provider.Name,
          ApiString: this.provider.ApiString
         });
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var tempProv = <SmsProvider>{};

    tempProv.Name = this.form.value.Name;
    tempProv.ApiString = this.form.value.ApiString;

    if (this.editMode) {
      tempProv.Id = this.provider.Id;
      tempProv.CreatedDate = this.provider.CreatedDate;
      var url = this.baseUrl + "api/data/SMSProvider/";
      this.http
        .post<SmsProvider>(url, tempProv)
        .subscribe(result => {
          this.provider = result;
          console.log("SMS provider " + this.provider.Name + " has been updated.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["smsprovider"]);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/SMSProvider/";
      this.http
        .put<SmsProvider>(url, tempProv)
        .subscribe(result => {
          this.provider = result;
          console.log("SMS provider  " + this.provider.Name + " has been created.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["smsprovider"]);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedId") {
          if (to != "0") {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/SMSProvider/" + this.provider.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
}

