import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder } from '@angular/forms';



@Component({
  selector: 'udp',
  templateUrl: './udp.component.html',
  styleUrls: ['./udp.component.less']
})
export class UdpComponent implements OnInit {
  title: string;
  udp: Udp[];
  httpAddr: Http[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;
  showTimeSet: boolean;
  form: FormGroup;
  checkTime: string;
  udpCheck: UDPCheck;
    visibleHttp: boolean;
    editModeHttp: boolean;
    titleHttp: string;
    selectedIdHttp: number;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.selectedIdHttp = 0;
    this.visible = false;
    this.visibleHttp = false;
    this.updateList();
    this.createForm();
    this.getCheckTime();
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      this.visibleHttp = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl + "api/data/UDPAddress/";
    this.title = "Список UDP адресов";
    this.http.get<Udp[]>(url).subscribe(result => {
      this.udp = result;
      this.elementsCount = this.udp.length;
    }), error => console.error(error);

    url = this.baseUrl + "api/data/HttpAddresses/";
    this.titleHttp = "Список Http адресов";
    this.http.get<Http[]>(url).subscribe(result => {
      this.httpAddr = result;
      this.elementsCount = this.httpAddr.length;
    }), error => console.error(error);

  }

  onSelect(udp: Udp) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = udp.Id;
    console.log("UDP with IP " + udp.IPAddress + " has been selected");
  }

  onSelectHttp(http: Http) {
    this.visibleHttp = true;
    this.editModeHttp = true;
    this.selectedIdHttp = http.Id;
    console.log("HttpUrl " + http.HttpUrl + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);
  }

  onAdd() {
    this.visible = true;
    this.editMode = false;
  }

  onAddHttp() {
    this.visibleHttp = true;
    this.editModeHttp = false;
}

  onEveryDayCheck() {
    this.showTimeSet = true;
    this.form.setValue({
      Time: this.udpCheck.CheckTime,
      Message: this.udpCheck.MessageToUDP
    });
  }

  onSaveTimeCheck() {
   
    let uCheck = <UDPCheck>{};
    uCheck.CheckTime = this.form.value.Time;
    uCheck.MessageToUDP = this.form.value.Message;

    var url = this.baseUrl;
    url = url + "api/data/checktimeUDP";

    this.http.post<UDPCheck>(url, uCheck).subscribe(result => {
      this.udpCheck = result;
      this.showTimeSet = false;
    }), error => console.error(error);
  }

  getCheckTime() {
    var url = this.baseUrl;
    url = url + "api/data/checktimeUDP";
    this.http.get<UDPCheck>(url).subscribe(result => {
      this.udpCheck = result;
    }), error => console.error(error);
  }

  createForm() {
    this.form = this.fb.group({
      Time: [''],
      Message: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

}
