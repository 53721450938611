import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'mail-edit',
  templateUrl: './email-edit.component.html',
  styleUrls: ['./email-edit.component.less']
})

export class MailEditComponent  implements OnChanges{
  title: string;
  email: AlarmEmail;
  form: FormGroup;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();
   
  }

  createForm() {
      this.form = this.fb.group({
        XpostName: ['', Validators.required],
        EmailAddress: ['', Validators.required],
      });
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/AchtungEmail/" + this.selectedId;
      this.http.get<AlarmEmail>(url).subscribe(result => {
        this.email = result;
        this.form.setValue({
          XpostName: this.email.XpostName,
          EmailAddress: this.email.EmailAddress
        });
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var tempEmail = <AlarmEmail>{};

    tempEmail.XpostName = this.form.value.XpostName;
    tempEmail.EmailAddress = this.form.value.EmailAddress;

    if (this.editMode) {
      tempEmail.Id = this.email.Id;
      tempEmail.CreatedDate = this.email.CreatedDate;
      var url = this.baseUrl + "api/data/AchtungEmail/";
      this.http
        .post<AlarmEmail>(url, tempEmail)
        .subscribe(result => {
          this.email = result;
          console.log("Email " + this.email.EmailAddress + " has been updated.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["emails"]);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/AchtungEmail/";
      this.http
        .put<AlarmEmail>(url, tempEmail)
        .subscribe(result => {
          this.email = result;
          console.log("Email " + this.email.EmailAddress + " has been created.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["emails"]);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedId") {
          if (to != "0") {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/AchtungEmail/" + this.email.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
}

