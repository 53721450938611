import { Component } from "@angular/core";

@Component({
  selector: "pagenotfound",
  templateUrl: "./pagenotfound.component.html"
})

export class PageNotFoundComponent {
  title = "Page not Found";
}
