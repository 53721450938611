import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: 'admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.less']
})

export class AdminsComponent implements OnInit {
  title: string;
  admin: LocalAdmin[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl + "api/data/LocalAdminAccount/";
    this.title = "Локальные админы";
    this.http.get<LocalAdmin[]>(url).subscribe(result => {
      this.admin = result;
      this.elementsCount = this.admin.length;
    }), error => console.error(error);
  }

  onSelect(admin: LocalAdmin) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = admin.Id;
    console.log("Admin account " + admin.Name + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);

  }

  onAdd() {
    this.visible = true;
    this.editMode = false;
  }
}

