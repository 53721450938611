import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'mobile-edit',
  templateUrl: './mobile-edit.component.html',
  styleUrls: ['./mobile-edit.component.less']
})

export class MobileEditComponent implements OnChanges {
  title: string;
  device: MobileDevice;
  form: FormGroup;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Input() name: String;
  @Input() smsCode: String;
  @Input() blockPhoneId: number;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();

  }

  createForm() {
    this.form = this.fb.group({
      Name: ['', Validators.required],
      SMSCode: ['', Validators.required],
      BlockPhoneId: ['', Validators.required]
    });
  }

  setFormData() {
    if (!this.smsCode) this.smsCode = "";
    if (this.name) {
      this.form.setValue({
        Name: this.name,
        SMSCode: this.smsCode,
        BlockPhoneId: this.blockPhoneId
      });
    }    
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/MobileDevice/" + this.selectedId;
      this.http.get<MobileDevice>(url).subscribe(result => {
        this.device = result;
        this.form.setValue({
          Name: this.device.UserName,
          SMSCode: this.device.SMSCode,
          BlockPhoneId: this.device.BlockPhoneId
        });
      }), error => console.error(error);
    }
  }

  onSubmit() {
    var tempDevice = <MobileDevice>{};

    tempDevice.UserName = this.form.value.Name;
    tempDevice.SMSCode = this.form.value.SMSCode;
    tempDevice.BlockPhoneId = this.form.value.BlockPhoneId;

    if (this.editMode) {
      tempDevice.Id = this.device.Id;     
      var url = this.baseUrl + "api/data/MobileDevice/";
      this.http
        .post<MobileDevice>(url, tempDevice)
        .subscribe(result => {
          this.device = result;
          console.log("Device " + this.device.UserName + "  has been updated.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["mobile"]);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/MobileDevice/";
      this.http
        .put<MobileDevice>(url, tempDevice)
        .subscribe(result => {
          this.device = result;
          console.log("Office " + this.device.UserName + " has been created.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["location"]);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.visible = false;
    for (let propName in changes) {
      let changedProp = changes[propName];
      let to = JSON.stringify(changedProp.currentValue);
      console.log(propName + "=" + to);

      if (propName == "visible") {
        if (to == "true") {
          this.visible = true;
        }
      }
      if (propName == "selectedId") {
        if (to != "0") {
          this.visible = true;
        }
      }
    }
    //this.setFormData();
    this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/MobileDevice/" + this.device.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
}

