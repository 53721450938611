import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'phone-edit',
  templateUrl: './phone-edit.component.html',
  styleUrls: ['./phone-edit.component.less']
})

export class PhoneEditComponent  implements OnChanges{
  title: string;
  phone: Phone;
  form: FormGroup;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();
  }

  createForm() {
      this.form = this.fb.group({
        XpostName: [''],
        Number: ['', Validators.required],
        Note: [''],
      });
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/Phone/" + this.selectedId;
      this.http.get<Phone>(url).subscribe(result => {
        this.phone = result;
        this.form.setValue({
          XpostName: this.phone.XpostName,
          Number: this.phone.Number,
          Note: this.phone.Note
        });
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var tempPhone = <Phone>{};

    tempPhone.XpostName = this.form.value.XpostName;
    tempPhone.Number = this.form.value.Number;
    tempPhone.Note = this.form.value.Note;

    if (this.editMode) {
      tempPhone.Id = this.phone.Id;
      tempPhone.CreatedDate = this.phone.CreatedDate;
      var url = this.baseUrl + "api/data/Phone/";
      this.http
        .post<Phone>(url, tempPhone)
        .subscribe(result => {
          this.phone = result;
          console.log("Phone number " + this.phone.Number + " has been updated.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["phones"]);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/Phone/";
      this.http
        .put<Phone>(url, tempPhone)
        .subscribe(result => {
          this.phone = result;
          console.log("Phone number " + this.phone.Number + " has been created.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["phones"]);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedId") {
          if (to != "0") {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/Phone/" + this.phone.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
}

