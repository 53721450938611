import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";


@Component({
  selector: 'location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.less']
})
export class LocationComponent implements OnInit {
  title: string;
  location: LocationOffice[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;
  updateComps: number;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
    this.updateComps = 1;
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  onNeedUpdateComps(needUpdateComps: boolean) {
    if (needUpdateComps == true) {
      console.log("onNeedUpdateComps");
      this.updateComps++;
    }
  }

  updateList() {
    var url = this.baseUrl + "api/data/Location/";
    this.title = "Офисы";
    this.http.get<LocationOffice[]>(url).subscribe(result => {
      this.location = result;
      this.elementsCount = this.location.length;
    }), error => console.error(error);
  }

  onSelect(location: LocationOffice) {
    this.selectedId = location.Id;
    console.log("Office " + location.Name + " has been selected");
    this.visible = true;
    this.editMode = true;
  }

  onBack() {
    this.router.navigate(["home"]);

  }
  onAdd() {
    this.visible = true;
    this.editMode = false;
  }
}
