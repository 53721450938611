import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'locationredirect',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.less']
})

export class LocationRedirectComponent implements OnInit {

  constructor(
    private router: Router) {
  }

  ngOnInit() {
    this.router.navigate(["location"]);
  }
}
