import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: 'smsstring',
  templateUrl: './smsstring.component.html',
  styleUrls: ['./smsstring.component.less']
}) 

export class SmsstringComponent implements OnInit {
  title: string;
  sms: SmsString[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl + "api/data/SMSString/";
    this.title = "СМС оповещение";
    this.http.get<SmsString[]>(url).subscribe(result => {
      this.sms = result;
      this.elementsCount = this.sms.length;
    }), error => console.error(error);
  }

  onSelect(sms: SmsString) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = sms.Id;
    console.log("SMS string " + sms.Id + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);

  }
  onAdd() {
    this.visible = true;
    this.editMode = false;
  }

}
