import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from '../service/auth.service';
import { error } from "protractor";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.less']
})

export class MobileComponent implements OnInit {
  titleMain: string;
  title: string;
  devices: MobileDevice[];
  selectedId: number;
  elementsCount: number;
  user: User;
  name: String;
  smsCode: String;
  blockPhoneId: number;
  isAdmin: boolean;
  visible: boolean;
  editMode: boolean;
  form: FormGroup;

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  createForm() {
    this.form = this.fb.group({
      Time: ['']
    });
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  ngOnInit() {
    this.createForm();
    this.editMode = false;
    this.visible = false;
    this.getUser();
    this.selectedId = 0;
    this.titleMain = "Установленные кнопки"
  }

  getUser() {
    //Get User data
    this.http.get<User>('api/user')
      .subscribe(result => {
        this.user = result;
        this.name = this.user.UserName;
        this.isAdmin = (this.user.Roles.indexOf("Administrator") != -1);
        console.log("We get user!")
        this.updateList();
      }, error => console.log(error));
  }

  updateList() {
    var url = this.baseUrl + "api/mobile/buttons/";
    this.title = "Установленные мобильные кнопки";
    this.http.get<MobileDevice[]>(url).subscribe(result => {
      this.devices = result;
      this.elementsCount = this.devices.length;
    }), error => console.error(error);
  }

  onSelect(device: MobileDevice) {
    this.visible = true;
    this.editMode = true;
    this.name = device.UserName;
    this.smsCode = device.SMSCode;
    this.blockPhoneId = device.BlockPhoneId;
    this.selectedId = device.Id;
    console.log("Button " + device.Id + " has been selected");
    console.log("this.visible " + this.visible + "  !!");
  }

  onDelete(device: MobileDevice) {
    var url = this.baseUrl + "api/data/MobileDevice/" + device.Id;
    this.http.delete(url).subscribe(result => {
      console.log("Mobile device with " + device.Id + "has been deleted");
      this.updateList();
    }), error => console.error(error);
  }

  onActivate(device: MobileDevice) {
    var url = this.baseUrl + "api/mobile/activate/" + device.Id;
    this.http.get(url).subscribe(result => {
      console.log("Mobile device with " + device.Id + "has been activated");
      this.updateList();
    }), error => console.error(error);
  }

  onBack() {
    this.router.navigate(["home"]);

  }

  updateSetting() {
    var url = this.baseUrl + "api/mobile/update";
    this.http.get(url).subscribe(result => {
      console.log("Update comand has been sent!");
      this.updateList();
    }), error => console.error(error);
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("microtic.component.onNeedUpdate");
      this.updateList();
    }
  }
}


