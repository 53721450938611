import { Component, Inject, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges, NgModule } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable, Subscription } from "rxjs";

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less']
})

export class FileUpload implements OnInit {
  form: FormGroup;
  fileToUpload: File = null;
  
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router
  )
  {
  }

  createForm() {
    this.form = this.fb.group({
      
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

 ngOnInit() {
    this.createForm(); 
    
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.postFile(this.fileToUpload).subscribe(data => {
      console.log("File upload succsessfully!");
    }, error => {
      console.log(error);
    });
  }

  postFile(fileToUpload: File): Observable<boolean> {
    var url = this.baseUrl;
    url = url + "api/data/checktime";
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(url, formData,)
      .map(() => { return true; })
      .catch((e) => this.handleError(e));
  }

  handleError(e) : Observable<boolean>  {
    console.log(e);
    return Observable.of(false);
  }
}
