import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatNativeDateModule, MatInputModule, MatButtonModule, MatCardModule, MatTableModule, MatFormFieldModule, MatCheckboxModule, MatDatepickerModule, MatRadioModule, MatSelectModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService } from '../app/service/auth.service';
import { AuthInterceptor } from './service/auth.interceptor';
import { AuthResponseInterceptor } from './service/auth.response.interceptor';
import { AfterIfDirective } from './service/after-if.directive';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './user/register.component';

import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';

import { MicroticComponent } from './microtic/microtic.component';
import { MicroticEditComponent } from './microtic/microtic-edit.component';

import { FileUpload } from './file/file-upload.component';

import { UpdateComponent } from './microtic/update.component';

import { UdpComponent } from './udp/udp.component';
import { UdpEditComponent } from './udp/udp-edit.component';
import { HttpEditComponent } from './udp/http-edit.component';

import { LocationComponent } from './location/location.component';
import { LocationRedirectComponent } from './location/locationredirect.component';
import { LocationEditComponent } from './location/location-edit.component';

import { LocalcompComponent } from './localcomp/localcomp.component';
import { LocalCompEditComponent } from './localcomp/localcomp-edit.component';

import { AdminsComponent } from './admins/admins.component';
import { AdminEditComponent } from './admins/admin-edit.component';

import { PhonesComponent } from './phones/phones.component';
import { PhoneEditComponent } from './phones/phone-edit.component';

import { EmailsComponent } from './emails/emails.component';
import { MailEditComponent } from './emails/email-edit.component';

import { ExchangeComponent } from './exchange/exchange.component';
import { ExchangeEditComponent } from './exchange/exchange-edit.component';

import { SmsstringComponent } from './smsstring/smsstring.component';
import { SmsEditComponent } from './smsstring/sms-edit.component';

import { SystemusersComponent } from './systemusers/systemusers.component';
import { SystemuserEditComponent } from './systemusers/systemuser-edit.component';

import { MobileComponent } from './mobile/mobile.component';
import { MobileEditComponent } from './mobile/mobile-edit.component';

import { LocalinstancesComponent } from './localinstances/localinstances.component';

import { SmsproviderComponent } from './smsprovider/smsprovider.component';
import { ProviderEditComponent } from './smsprovider/provider-edit.component';
import { ProgressComponent } from './progress/progress.component';
import { BlockPhonesComponent } from './blockphones/blockphones.component';
import { BlockPhonesEditComponent } from './blockphones/blockphones-edit.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    AfterIfDirective,
    LoginComponent,
    RegisterComponent,
    PageNotFoundComponent,
    MicroticComponent,
    MicroticEditComponent,
    FileUpload,
    UpdateComponent,
    UdpComponent,
    UdpEditComponent,
    HttpEditComponent,
    LocationComponent,
    LocationRedirectComponent,
    LocationEditComponent,
    LocalcompComponent,
    LocalCompEditComponent,
    AdminsComponent,
    AdminEditComponent,
    PhonesComponent,
    PhoneEditComponent,
    EmailsComponent,
    MailEditComponent,
    ExchangeComponent,    
    ExchangeEditComponent,
    BlockPhonesComponent,
    BlockPhonesEditComponent,
    SmsstringComponent,
    SmsEditComponent,
    SystemusersComponent,
    SystemuserEditComponent,
    MobileComponent,
    MobileEditComponent,
    LocalinstancesComponent,
    SmsproviderComponent,
    ProviderEditComponent,
    ProgressComponent
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    BrowserAnimationsModule,
    
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'microtics', component: MicroticComponent },
      { path: 'udp', component: UdpComponent },
      { path: 'blockphones', component: BlockPhonesComponent },
      { path: 'admins', component: AdminsComponent },
      { path: 'emails', component: EmailsComponent },
      { path: 'exchange', component: ExchangeComponent },
      { path: 'localcomp', component: LocalcompComponent },
      { path: 'localinstances', component: LocalinstancesComponent },
      { path: 'location', component: LocationComponent },
      { path: 'locationr', component: LocationRedirectComponent },
      { path: 'mobile', component: MobileComponent },
      { path: 'phones', component: PhonesComponent },
      { path: 'smsstring', component: SmsstringComponent },
      { path: 'systemusers', component: SystemusersComponent },
      { path: 'smsprovider', component: SmsproviderComponent },
      { path: 'update/:return', component: UpdateComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: '**', component: PageNotFoundComponent }
    ])
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthResponseInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
