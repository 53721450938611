import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";


@Component({
  selector: 'emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.less']
})
export class EmailsComponent implements OnInit {
  title: string;
  email: AlarmEmail[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl + "api/data/AchtungEmail/";
    this.title = "Email адреса";
    this.http.get<AlarmEmail[]>(url).subscribe(result => {
      this.email = result;
      this.elementsCount = this.email.length;
    }), error => console.error(error);
  }

  onSelect(email: AlarmEmail) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = email.Id;
    console.log("Email address " + email.EmailAddress + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);

  }

  onAdd() {
    this.visible = true;
    this.editMode = false;
  }
}
