import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'exchange-edit',
  templateUrl: './exchange-edit.component.html',
  styleUrls: ['./exchange-edit.component.less']
})

export class ExchangeEditComponent  implements OnChanges{
  title: string;
  exch: Exchange;
  form: FormGroup;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();
  }

  createForm() {
      this.form = this.fb.group({
        Server: ['https://mail.xpost.top/EWS/Exchange.asmx', Validators.required],
        Login: ['', Validators.required],
        Password: ['', Validators.required],
        PasswordConfirm: ['', Validators.required],
        Domen: ['', Validators.required]
    }
        ,{
      validator: this.passwordConfirmValidator
    });
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/ExchangeAccount/" + this.selectedId;
      this.http.get<Exchange>(url).subscribe(result => {
        this.exch = result;
        this.form.setValue({
          Server: this.exch.Server,
          Login: this.exch.Login,
          Password: this.exch.Password,
          PasswordConfirm: this.exch.Password,
          Domen: this.exch.Domen
        });
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var tempExchange = <Exchange>{};

    tempExchange.Server = this.form.value.Server;
    tempExchange.Login = this.form.value.Login;
    tempExchange.Password = this.form.value.Password;
    tempExchange.Domen = this.form.value.Domen;

    if (this.editMode) {
      tempExchange.Id = this.exch.Id;
      tempExchange.CreatedDate = this.exch.CreatedDate;
      var url = this.baseUrl + "api/data/ExchangeAccount/";
      this.http
        .post<Exchange>(url, tempExchange)
        .subscribe(result => {
          this.exch = result;
          console.log("Exchange account " + this.exch.Login + " has been updated.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["exchange"]);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/ExchangeAccount/";
      this.http
        .put<Exchange>(url, tempExchange)
        .subscribe(result => {
          this.exch = result;
          console.log("Exchange account  " + this.exch.Login + " has been created.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["exchange"]);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedId") {
          if (to != "0") {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/ExchangeAccount/" + this.exch.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  // custom validator to compare 
  //   the Password and passwordConfirm values.
  passwordConfirmValidator(control: FormControl): any {

    // retrieve the two Controls
    let p = control.root.get('Password');
    let pc = control.root.get('PasswordConfirm');

    if (p && pc) {
      if (p.value !== pc.value) {
        pc.setErrors(
          { "PasswordMismatch": true }
        );
      }
      else {
        pc.setErrors(null);
      }
    }
    return null;
  }

}

