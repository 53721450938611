import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'update',
  template: ''
})

export class UpdateComponent {

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router) {
    var returnrout = this.activeRoute.snapshot.url[1].path;
    router.navigate([returnrout]);
  }
}
