import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from "rxjs";
import { templateJitUrl } from '@angular/compiler';


@Component({
  selector: 'microtic-edit',
  templateUrl: './microtic-edit.component.html',
  styleUrls: ['./microtic-edit.component.less']
})

  //Child from MicroticCompomemt
export class MicroticEditComponent  implements OnChanges{
  title: string;
  microtic: Microtic;
  form: FormGroup;
  isShowProgress: boolean;
  progressProcent: number;
  progressStep: number;
  progressValue: string;
  timer: Observable<number>;
  timerSubscription: Subscription;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Input() location: number;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {
    this.isShowProgress = false;
    this.progressValue = "0%";
    this.progressProcent = 0;
    this.progressStep = 5;
    this.timer = Observable.timer(1000, 1000);
    this.visible = false;
    this.createForm();
   
  }

  createForm() {
      const ipPattern =
        "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
    this.form = this.fb.group({
      Name: ['', Validators.required],
      IpAddress: ['', Validators.required && Validators.pattern(ipPattern)],
      Port: ['', Validators.required],
      Login: ['', Validators.required],
      Password: ['', Validators.required],
      PasswordConfirm: ['', Validators.required],
      IsLocal: [false]
    }, {
        validator: this.passwordConfirmValidator
      }
    );
  }


  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/Microtic/" + this.selectedId;
      this.http.get<Microtic>(url).subscribe(result => {
        this.microtic = result;
        this.form.setValue({
          Name: this.microtic.Name,
          IpAddress: this.microtic.IpAddress,
          Port: this.microtic.Port,
          Login: this.microtic.Login,
          Password: this.microtic.Password,
          PasswordConfirm: this.microtic.Password,
          IsLocal: this.microtic.IsLocal
        });
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var tempMicrotic = <Microtic>{};

    tempMicrotic.Name = this.form.value.Name;
    tempMicrotic.IpAddress = this.form.value.IpAddress;
    tempMicrotic.Port = this.form.value.Port;
    tempMicrotic.Login = this.form.value.Login;
    tempMicrotic.Password = this.form.value.Password;
    if (this.location) tempMicrotic.IsLocal = true;
    else tempMicrotic.IsLocal = false;

    this.isShowProgress = true;
    this.StartTimer();

    if (this.editMode) {
      tempMicrotic.Id = this.microtic.Id;
      tempMicrotic.CreatedDate = this.microtic.CreatedDate;
      tempMicrotic.LocationId = this.microtic.LocationId;
      tempMicrotic.IsConnected = this.microtic.IsConnected;
      var url = this.baseUrl + "api/data/Microtic/";
      this.http
        .post<Microtic>(url, tempMicrotic)
        .subscribe(result => {
          this.microtic = result;
          console.log("Microtic " + this.microtic.IpAddress + " has been updated.");
          this.visible = false;

          this.StopTimer();
          this.isShowProgress = false;

          this.needUpdate.emit(true);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/Microtic/";
      if (this.location) tempMicrotic.LocationId = this.location;
      this.http
        .put<Microtic>(url, tempMicrotic)
        .subscribe(result => {
          this.microtic = result;
          console.log("Microtic " + this.microtic.IpAddress + " has been created.");
          this.visible = false;

          this.StopTimer();
          this.isShowProgress = false;

          this.needUpdate.emit(true);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedId") {
          if (to && to != "0") {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/Microtic/" + this.microtic.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  StartTimer() {
    this.timerSubscription = this.timer.subscribe(() => {

      this.progressProcent += this.progressStep;
      this.progressProcent = parseFloat(this.progressProcent.toFixed());

      if (this.progressProcent > 100) {
        this.progressProcent = 100;
        this.progressValue = this.progressProcent + "%";
        this.StopTimer();
        this.isShowProgress = false;
      }

      this.progressValue = this.progressProcent + "%";
    }
    );
  }

  StopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      console.log("Unsubscribe from timing");
      this.progressProcent = 0;
    }
  }

  // custom validator to compare 
  //   the Password and passwordConfirm values.
  passwordConfirmValidator(control: FormControl): any {

    // retrieve the two Controls
    let p = control.root.get('Password');
    let pc = control.root.get('PasswordConfirm');

    if (p && pc) {
      if (p.value !== pc.value) {
        pc.setErrors(
          { "PasswordMismatch": true }
        );
      }
      else {
        pc.setErrors(null);
      }
    }
    return null;
  }

}

