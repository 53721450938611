import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.less']
})

export class AdminEditComponent  implements OnChanges{
  title: string;
  admin: LocalAdmin;
  form: FormGroup;
  @Input() selectedId: number;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();
   
  }

  createForm() {
      this.form = this.fb.group({
        Name: ['', Validators.required],
        Login: ['', Validators.required],
        Password: ['', Validators.required],
        PasswordConfirm: ['', Validators.required],
        Domain: [''],
      }, {
          validator: this.passwordConfirmValidator
        }
      );
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/data/LocalAdminAccount/" + this.selectedId;
      this.http.get<LocalAdmin>(url).subscribe(result => {
        this.admin = result;
        this.form.setValue({
          Name: this.admin.Name,
          Login: this.admin.Login,
          Password: this.admin.Password,
          PasswordConfirm: this.admin.Password,
          Domain: this.admin.Domain
        });
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var tempAdmin = <LocalAdmin>{};

    tempAdmin.Name = this.form.value.Name;
    tempAdmin.Login = this.form.value.Login;
    tempAdmin.Password = this.form.value.Password;
    tempAdmin.Domain = this.form.value.Domain;

    if (this.editMode) {
      tempAdmin.Id = this.admin.Id;
      tempAdmin.CreatedDate = this.admin.CreatedDate;
      var url = this.baseUrl + "api/data/LocalAdminAccount/";
      this.http
        .post<LocalAdmin>(url, tempAdmin)
        .subscribe(result => {
          this.admin = result;
          console.log("Admin account " + this.admin.Name + " has been updated.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["admins"]);
        }, error => console.error(error));
    }
    else {
      var url = this.baseUrl + "api/data/LocalAdminAccount/";
      this.http
        .put<LocalAdmin>(url, tempAdmin)
        .subscribe(result => {
          this.admin = result;
          console.log("Admin account " + this.admin.Name + " has been created.");
          this.visible = false;
          this.needUpdate.emit(true);
          this.router.navigate(["admins"]);
        }, error => console.error(error));
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedId") {
          if (to != "0") {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/data/LocalAdminAccount/" + this.admin.Id;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  // custom validator to compare 
  //   the Password and passwordConfirm values.
  passwordConfirmValidator(control: FormControl): any {

    // retrieve the two Controls
    let p = control.root.get('Password');
    let pc = control.root.get('PasswordConfirm');

    if (p && pc) {
      if (p.value !== pc.value) {
        pc.setErrors(
          { "PasswordMismatch": true }
        );
      }
      else {
        pc.setErrors(null);
      }
    }
    return null;
  }
}

