import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: 'systemusers',
  templateUrl: './systemusers.component.html',
  styleUrls: ['./systemusers.component.less']
})
export class SystemusersComponent implements OnInit {
  title: string;
  users: User[];
  selectedId: number;
  selectedMail: string;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl + "api/user/all/";
    this.title = "Пользователи системы";
    this.http.get<User[]>(url).subscribe(result => {
      this.users = result;
      this.elementsCount = this.users.length;
    }), error => console.error(error);
    console.log("updateList visible = " + this.visible);
  }

  onSelect(user: User) {
    this.visible = true;
    this.editMode = true;
    this.selectedMail = user.Email;
    console.log("User " + user.Email + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);
  }

  onAdd() {
    this.visible = true;
    this.editMode = false;
  }

}
