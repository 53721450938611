import { Component, Inject, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import * as $ from 'jquery';

@Component({
  selector: 'localcomp',
  templateUrl: './localcomp.component.html',
  styleUrls: ['./localcomp.component.less']
})

export class LocalcompComponent implements OnChanges {
  @Input() location: number;
  @Input() updateComponent: number;
  title: string;
  comp: LocalComp[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;
  isShowProgress: boolean;
  timer: Observable<number>;
  timerSubscription: Subscription;
  progressProcent: number;
  progressStep: number;
  progressValue: string;
  progressTitle: string;
  IsInstallButton: boolean;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) {
    this.isShowProgress = false;
    this.progressValue = "0%";
    this.progressProcent = 0;
    this.timer = Observable.timer(2000, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
    console.log("localcomp - ngOnChanges");
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
      console.log("Updated");
    }
  }

  updateList() {
    var url = this.baseUrl;
    if (this.location) {
      url = url + "api/data/Location/LocalComputer/" + this.location;
    }
    else {
      url = url + "api/data/LocalComputer/";
    }

    this.title = "Локальные компьютеры";
    this.http.get<LocalComp[]>(url).subscribe(result => {
      this.comp = result;
      this.elementsCount = this.comp.length;
      let alreadyCheck = 0;
      this.comp.forEach(function (value) {
        if (value.LocalAdminAccountId) {
          alreadyCheck++;
        }
      });
      if (this.IsInstallButton == false) {
        if (this.progressProcent < 100) {
          let percent = alreadyCheck / this.elementsCount * 100;
          this.progressProcent = percent;
        }
      }

      if (!this.progressStep) this.progressStep = 100 / this.elementsCount / 3;
    }), error => console.error(error);
  }

  onSelect(comp: LocalComp) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = comp.Id;
    console.log("Computer " + comp.NetworkName + " has been selected");
  }

  onCheckComp(comp: LocalComp) {
    this.comp.forEach(function (value) {
      if (comp.Id == value.Id) {
        if (comp.IsCheckForDelete == true) comp.IsCheckForDelete = false;
        else comp.IsCheckForDelete = true;
      }
    });
  }

  onCheckAll() {
    let t = 0;
    this.comp.forEach(function (value) {
      if (value.IsCheckForDelete == true) {
        t++;
      }
    });
    if (t >= this.comp.length) {
      this.comp.forEach(function (value) {
        value.IsCheckForDelete = false;
      });
    }
    else {
      this.comp.forEach(function (value) {
        value.IsCheckForDelete = true;
      });
    }
  }

  onDeleteAll() {
    let url = this.baseUrl + "api/data/LocalComputer/";


    for (var i = 0; i < this.comp.length; i++) {
      if (this.comp[i].IsCheckForDelete == true) {
        this.http.delete(url + this.comp[i].Id).subscribe(result => {
        }, error => console.error(error));
        this.comp.splice(i, 1);
        i--;
      }
    }
    //this.updateList();
  }

  onDeleteComp(comp: LocalComp, message: string) {
    window.scroll(0, 0);
    let url = this.baseUrl + "api/local/remove/" + comp.Id;

    this.progressProcent = 1;    
    this.isShowProgress = true;
    this.progressStep = 2;
    this.progressTitle = message + " службы и приложения на " + comp.NetworkName;
    this.StartTimer();

    this.http.get(url).subscribe(result => {
      this.progressProcent = 100;
      this.StopTimer();
      this.isShowProgress = false;
      this.updateList();
    }), error => {
      this.progressTitle = "Установка кнопки завершилась с ошибкой!!!"
      setTimeout(() => { console.log("pause") }, 2000);
      this.progressProcent = 100;
      this.StopTimer();
      this.isShowProgress = false;
      this.updateList();
      console.error(error);
    }
  }
  

  onReinstallComp(comp: LocalComp) {
    let url = this.baseUrl + "api/local/reinstall/";
    this.http.get(url + comp.Id).subscribe(result => {
    }, error => console.error(error));
    this.updateList();
  }

  onBack() {
    this.router.navigate(["home"]);
  }

  onAdd() {
    this.visible = true;
    this.editMode = false;
  }

  //Bind computers with Admin accounts
  onCheck() {

    //When some computers checked
    let checkedComps = this.comp.filter(e => e.IsCheckForDelete === true);

    let compsToRequest: LocalComp[];
    if (checkedComps.length > 0) {
      compsToRequest = checkedComps;
    }
    else {
      compsToRequest = this.comp;
    }

    this.IsInstallButton = false;
    this.progressStep = 100 / compsToRequest.length/3;
    var url = this.baseUrl + "api/data/bindcomps";
    this.isShowProgress = true;
    this.progressTitle = "Связываем локальные компьютеры с доступными учетками";
    this.StartTimer();

    this.http.post<LocalComp[]>(url, compsToRequest).subscribe(result => {
      this.comp = result;
      this.progressProcent = 100;
      this.StopTimer();
      this.updateList();
      this.isShowProgress = false;
      console.log("Finish bind computers");
    }), error => {
      console.log("onCheck - error!");
      this.isShowProgress = false;
      this.progressProcent = 100;
      this.StopTimer();
      this.updateList();
      console.error(error)
    };
  }

  StartTimer() {
    this.timerSubscription = this.timer.subscribe(() => {

      this.progressProcent += this.progressStep;
      this.progressProcent = parseFloat(this.progressProcent.toFixed());

      if (this.progressProcent > 100) {
        this.progressProcent = 100;
        this.progressValue = this.progressProcent + "%";
        this.StopTimer();
        this.isShowProgress = false;
      }

      this.progressValue = this.progressProcent + "%";
      this.updateList();
    }
    );
  }

  StopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      console.log("Unsubscribe from timing");
      this.progressProcent = 0;
      this.isShowProgress = false;
    }
  }

  onInstallButton(comp: LocalComp, message: string) {

    window.scroll(0, 0);

    this.IsInstallButton = true;
    this.progressProcent = 1;
    var url = this.baseUrl + "api/local/install/" + comp.Id;
    this.isShowProgress = true;
    this.progressStep = 2;
    this.progressTitle = message + " службы и приложения на " + comp.NetworkName;
    this.StartTimer();

    this.http.get<LocalComp>(url).subscribe(result => {
      this.progressProcent = 100;
      this.StopTimer();
      this.isShowProgress = false;
      this.updateList();
    }), error => {
      this.progressTitle = "Установка кнопки завершилась с ошибкой!!!"
      setTimeout(() => { console.log("pause")}, 2000);
      this.progressProcent = 100;
      this.StopTimer();
      this.isShowProgress = false;
      this.updateList();
      console.error(error);
    }
  }
  
}

