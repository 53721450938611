import { Component, OnChanges, Inject, Input, SimpleChange, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'systemuser-edit',
  templateUrl: './systemuser-edit.component.html',
  styleUrls: ['./systemuser-edit.component.less']
})

export class SystemuserEditComponent  implements OnChanges{
  title: string;
  user: User;
  form: FormGroup;
  isAdmin: boolean;
  @Input() selectedMail: string;
  @Input() visible: boolean;
  @Input() editMode: boolean;
  @Output() needUpdate = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string) {

    this.visible = false;
    this.createForm();
   
  }

  createForm() {
      this.form = this.fb.group({
        UserName: ['', Validators.required],
        Email: ['', Validators.required],
      });
  }

  updateForm() {
    if (this.editMode == true && this.visible == true) {
      var url = this.baseUrl + "api/user/" + this.selectedMail;
      this.http.get<User>(url).subscribe(result => {
        this.user = result;
        this.form.setValue({
          UserName: this.user.UserName,
          Email: this.user.Email,
        });
        if (this.user.Roles.indexOf("Administrator") != -1) {
          this.isAdmin = true;
        }
      }), error => console.error(error);
    }
    }

  onSubmit() {
    var url = this.baseUrl + "api/user/makeadmin/" + this.user.Email;
    this.http.post(url, null).subscribe(result => {
      console.log(result);
      this.visible = false;
    }, error => console.error(error));
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
      this.visible = false;
      for (let propName in changes) {
        let changedProp = changes[propName];
        let to = JSON.stringify(changedProp.currentValue);
        console.log(propName + "=" + to);

        if (propName == "visible") {
          if (to == "true") {
            this.visible = true;
          }
        }
        if (propName == "selectedMail") {
          if (to) {
            this.visible = true;
          }
        }
      }
      this.updateForm();
  }

  onCancel() {
    this.visible = false;
    this.needUpdate.emit(true);
  }

  onDelete() {
    this.visible = false;
    var url = this.baseUrl + "api/user/" + this.user.Email;
    this.http.delete(url).subscribe(result => {
      console.log(result);
      this.needUpdate.emit(true);
    }, error => console.error(error));
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  // returns TRUE if the FormControl is valid
  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }

  // returns TRUE if the FormControl has been changed
  isChanged(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  // returns TRUE if the FormControl is invalid after user changes
  hasError(name: string) {
    var e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
}

