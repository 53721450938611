import { Component, Inject, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges, NgModule } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Time } from '@angular/common';
import { Observable, Subscription } from "rxjs";

@Component({
  selector: 'microtics',
  templateUrl: './microtic.component.html',
  styleUrls: ['./microtic.component.less']
})

export class MicroticComponent implements OnChanges, OnInit {
  @Input() location: number;
  @Output() needUpdateComps = new EventEmitter<boolean>();
  title: string;
  titleMain: string;
  microtics: Microtic[];
  microticIdToEdit: number;
  elementsCount: number;
  selectedMicrotic: Microtic;
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  showTimeSet: boolean;
  form: FormGroup;
  checkTime: string;
  rr: Time;
  fileToUpload: File = null;
  
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router
  )
  {
  }

  createForm() {
    this.form = this.fb.group({
      Time: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
    console.log("microtic.component.onInit");
  }

  ngOnInit() {
    this.createForm();
    this.editMode = false;
    this.visible = false;
    this.showTimeSet = false;
    if (this.location == undefined) this.location = 0;
    this.updateList();
    this.getCheckTime();
  }

  onNeedUpdate(needUpdate:boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("microtic.component.onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl;
    if (this.location) {
      url = url + "api/data/Location/Microtic/" + this.location;
      this.titleMain = "Локальные микротики";
      this.title = "Список локальных микротиков";
      this.needUpdateComps.emit(true);
    }
    else {
      this.titleMain = "Глобальные микротики";
      this.title = "Список глобальных микротиков";
      url = url + "api/data/Microtic/";
    }
    
    this.http.get<Microtic[]>(url).subscribe(result => {
      this.microtics = result;
      this.elementsCount = this.microtics.length;
    }), error => console.error(error);
  }

  onSelect(microtic: Microtic) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = microtic.Id;
    console.log("Microtic with IP " + microtic.IpAddress + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);
  }
  onAdd() {
    this.visible = true;
    this.editMode = false;
  }

  onEveryDayCheck() {
    this.showTimeSet = true;
    this.form.setValue({
      Time: this.checkTime
    });
  }

  onSaveTimeCheck() {
    this.showTimeSet = false;
    this.checkTime = this.form.value.Time;
    var url = this.baseUrl;
    url = url + "api/data/checktime/" + this.checkTime;
    this.http.get<string>(url).subscribe(result => {
      this.checkTime = result;
    }), error => console.error(error);
  }

  getCheckTime() {
    var url = this.baseUrl;
    url = url + "api/data/checktime";
    this.http.get<string>(url).subscribe(result => {
      this.checkTime = result;
    }), error => console.error(error);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.postFile(this.fileToUpload).subscribe(data => {
      console.log("File upload succsessfully!");
    }, error => {
      console.log(error);
    });
  }

  postFile(fileToUpload: File): Observable<boolean> {
    var url = this.baseUrl;
    url = url + "api/data/checktime";
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(url, formData,)
      .map(() => { return true; })
      .catch((e) => this.handleError(e));
  }

  handleError(e) : Observable<boolean>  {
    console.log(e);
    return Observable.of(false);
  }
}
