import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.less']
})

export class ProgressComponent implements OnChanges {
  @Input() isProgress: boolean;
  @Input() total: number;
  progressProcent: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isProgress) {
      this.startChecking(this.total);
    }
  }

  startChecking(compCount: number) {
    let step = 100 / compCount;
    var i: number;
    this.progressProcent = "0%";
    for (i = 0; i <= 100; i = i + step) {
      this.progressProcent = i.toFixed(0).toString() + "%";
      this.wait(2000);
    }
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

}
