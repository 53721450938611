import { Component, Inject, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: 'smsprovider',
  templateUrl: './smsprovider.component.html',
  styleUrls: ['./smsprovider.component.less']
})

export class SmsproviderComponent implements OnInit {
  title: string;
  provider: SmsProvider[];
  selectedId: number;
  visible: boolean;
  editMode: boolean;
  elementsCount: number;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router) { }

  ngOnInit() {
    this.selectedId = 0;
    this.visible = false;
    this.updateList();
  }

  onNeedUpdate(needUpdate: boolean) {
    if (needUpdate == true) {
      this.visible = false;
      console.log("onNeedUpdate");
      this.updateList();
    }
  }

  updateList() {
    var url = this.baseUrl + "api/data/SMSProvider/";
    this.title = "СМС провайдер";
    this.http.get<SmsProvider[]>(url).subscribe(result => {
      this.provider = result;
      this.elementsCount = this.provider.length;
    }), error => console.error(error);
  }

  onSelect(provider: SmsProvider) {
    this.visible = true;
    this.editMode = true;
    this.selectedId = provider.Id;
    console.log("SMS provider " + provider.Id + " has been selected");
  }

  onBack() {
    this.router.navigate(["home"]);

  }
  onAdd() {
    this.visible = true;
    this.editMode = false;
  }

}

